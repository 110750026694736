import React from 'react';
import {
  Article,
  ArticleDescription,
  ArticleGroup,
  ArticleGroupHeader,
  ArticleHeader,
  HelpArticlesContainer,
} from '../../styles/Pages/HelpCenterStyles';
import Icon from '../../styles/atoms/icons';

const HelpArticles = ({ articles, sections }) => {
  return (
    <HelpArticlesContainer>
      {articles?.length > 0 && (
        <ul className="article-list">
          {articles
            .sort((a, b) => a.title.localeCompare(b.title))
            .map(article => (
              <li key={article.url}>
                <Article
                  href={`/help/${article.url
                    .split('/')
                    .pop()
                    .replace(/\d+-/, '')}`}
                >
                  <div className="content">
                    <ArticleHeader>{article.title}</ArticleHeader>
                    {article.description && (
                      <ArticleDescription>
                        {article.description}
                      </ArticleDescription>
                    )}
                  </div>
                  <Icon className="chevron" id="FiChevronRight" />
                </Article>
              </li>
            ))}
        </ul>
      )}
      {sections?.length > 0 &&
        sections
          .sort((a, b) => a.order - b.order)
          .map(section => (
            <ArticleGroup>
              <ArticleGroupHeader>{section.name}</ArticleGroupHeader>
              <ul className="article-list">
                {section.children
                  .sort((a, b) => a.title.localeCompare(b.title))
                  .map(article => (
                    <li key={article.url}>
                      <Article
                        href={`/help/${article.url
                          .split('/')
                          .pop()
                          .replace(/\d+-/, '')}`}
                      >
                        <div className="content">
                          <ArticleHeader>{article.title}</ArticleHeader>
                          {article.description && (
                            <ArticleDescription>
                              {article.description}
                            </ArticleDescription>
                          )}
                        </div>
                        <Icon className="chevron" id="FiChevronRight" />
                      </Article>
                    </li>
                  ))}
              </ul>
            </ArticleGroup>
          ))}
    </HelpArticlesContainer>
  );
};

export default HelpArticles;
