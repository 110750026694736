import styled from 'styled-components';
import { atMinWidth } from '../atoms/breakpoints';
import { colors } from '../atoms/colors';
import { font } from '../atoms/typography';

export const HelpCenterContainer = styled.div`
  background: var(--light-gray);
  padding-top: 0;

  .menu-title {
    ${font('display', 'xs', '700')}
    color: #1d2030;
    margin-bottom: 0.75rem !important;

    ${atMinWidth.md`
      ${font('display', 'sm', '700')}
    `}
  }

  .sidebar {
    position: sticky;
    top: 130px;
    z-index: 1000;

    ${atMinWidth.xl`
      padding-right: 2rem;
    `}

    .menu-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: 24px;
    }
  }

  .active-collection-heading,
  .search-results-heading {
    ${font('display', 'sm', '700')}

    ${atMinWidth.md`
      ${font('display', 'md', '700')}
    `}
  }

  .active-collection-meta,
  .search-results-meta {
    ${font('text', 'sm', '400')}
    margin-bottom: 0.75rem;
  }
`;

export const SearchBarContainer = styled.div`
  width: 100%;
  margin-top: 21px;
`;

export const HelpCenterListContainer = styled.div`
  position: relative;
  z-index: 2;

  @media (max-width: 991px) {
    overflow: hidden;
  }

  > *:first-child {
    padding-top: 0;
  }

  .collection-wrapper {
    display: flex;
    flex-direction: column;

    .sm-input {
      display: none;
      margin-bottom: 56px;

      @media (max-width: 991px) {
        display: block;
        max-width: 269px;
      }

      @media (max-width: 767px) {
        max-width: 100%;
      }
    }
  }

  .collection-tag-filter {
    display: inline-block;
    padding: 16px;
    font-size: 22.5px;
    font-weight: bold;
    border-radius: 2px;
    cursor: pointer;

    @media (max-width: 991px) {
      padding: 4px 6px;
      font-weight: 400;
      font-size: 16px;
    }

    p {
      font-weight: normal;
      font-size: 18px;
      line-height: 110%;
      letter-spacing: -0.27px;
      margin-bottom: 0;
      color: #4d5061;
    }

    &.active {
      p {
        color: var(--primary-color);
        font-weight: 700;
      }
    }

    &.active,
    &:hover {
      background: #e9f2ff;
    }
  }

  .collection-list {
    display: none;
    margin-bottom: 36px;

    @media (max-width: 991px) {
      display: block;
    }

    @media (max-width: 768px) {
      margin-bottom: 16px;
    }

    > div:first-of-type {
      display: flex !important;
      justify-content: space-between;
      margin-bottom: 24px;

      @media (max-width: 991px) {
        flex-wrap: wrap;
        justify-content: flex-start;
        gap: 10px;
      }

      @media (max-width: 552px) {
        flex-wrap: wrap;
      }

      @media (max-width: 410px) {
        justify-content: flex-start;
      }
    }
  }
`;

export const HelpArticlesContainer = styled.div`
  .article-list {
    background-color: ${colors.base.white};
    list-style: none;
    display: flex;
    flex-direction: column;
    margin: 0;
    border-radius: 8px;
    border: 1px solid ${colors.primary[100]};
    padding: 8px;

    ${atMinWidth.md`
      padding: 20px;
    `}
  }
`;

export const ArticleGroup = styled.div`
  background-color: ${colors.base.white};
  border-radius: 8px;
  border: 1px solid ${colors.primary[100]};
  margin-top: 24px;
  overflow: hidden;

  .article-list {
    border: none;
  }
`;

export const ArticleGroupHeader = styled.h4`
  ${font('display', 'sm', '700')};
  color: ${colors.gray[900]};
  border-bottom: 1px solid ${colors.primary[100]};
  margin: 0 20px;
  padding: 20px 0;
  margin-bottom: 0 !important;

  ${atMinWidth.md`
    margin: 0 36px;
    padding: 30px 0;
  `}
`;

export const Article = styled.a`
  display: flex;
  gap: 16px;
  align-items: center;
  background-color: ${colors.base.white};
  border-radius: 8px;
  margin: 2px 0;
  padding: 8px 12px;
  overflow: hidden;
  cursor: pointer;

  ${atMinWidth.md`
    margin: 0;
    padding: 14px 16px;
  `}

  &:hover {
    background: #e9f2ff;
    text-decoration: none;

    .chevron {
      transform: translateX(4px);
    }
  }

  .content {
    flex: 1 1 auto;
  }

  .chevron {
    flex: 0 0 auto;
    width: 20px;
    height: 20px;
    margin: 0 -3px 0 6px;
    transition: transform 0.1s ease;
  }
`;

export const ArticleHeader = styled.p`
  ${font('text', 'md', '500')};
  line-height: 120%;
  color: ${colors.gray[900]};
  margin: 0 0 4px;
`;

export const ArticleDescription = styled.p`
  ${font('text', 'xs', '400')};
  color: ${colors.gray[700]};

  ${atMinWidth.md`
    ${font('text', 'sm', '400')};
  `}
`;
